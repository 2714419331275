import i18n from '@/i18n';

const state = {
  error: undefined,
  errorShowing: false,
  success: undefined,
  successShowing: false,
  locale: undefined,
  cookiesAccepted: false,
};

const getters = {
  errorShowing: ({ errorShowing }) => errorShowing,
  error: ({ error }) => error,
  successShowing: ({ successShowing }) => successShowing,
  success: ({ success }) => success,
  locale: ({ locale }) => locale,
  cookiesAccepted: ({ cookiesAccepted }) => cookiesAccepted,
};

const actions = {

};

const mutations = {
  setErrorShowing (state, errorShowing) {
    state.errorShowing = errorShowing;
  },
  displayError (state, { title = 'error', text }) {
    state.error = {
      title,
      text,
    };
    state.errorShowing = true;
  },
  setSuccessShowing (state, successShowing) {
    state.successShowing = successShowing;
  },
  displaySuccess (state, { title = 'success', text }) {
    state.success = {
      title,
      text,
    };
    state.successShowing = true;
  },
  setLocale (state, locale) {
    state.locale = locale;
    i18n.global.locale = locale;
  },
  setCookiesAccepted (state, cookiesAccepted) {
    state.cookiesAccepted = cookiesAccepted;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
