const routes = [
  {
    path: 'products',
    name: 'WarehouseProducts',
    component: () => import('@/views/warehouse/Products.vue'),
  },
  {
    path: 'stocks',
    name: 'WarehouseProductStocks',
    component: () => import('@/views/warehouse/ProductStocks.vue'),
  },
];

export default routes;
