import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import common from '@/store/common';
import auth from '@/store/auth';

export default new Vuex.Store({
  modules: {
    common,
    auth,
  },
  plugins: [createPersistedState({
    key: 'project-printcard',
    reducer: (state) => ({
      common: {
        cookiesAccepted: state.common.cookiesAccepted,
        locale: state.common.locale,
      },
      auth: {
        token: state.auth.token,
        tokenClientIat: state.auth.tokenClientIat,
      },
    }),
  })],
  strict: true,
});
