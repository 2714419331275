const routes = [
  {
    path: 'sales',
    name: 'Sales',
    component: () => import('@/views/business/Sales.vue'),
  },
  {
    path: 'addSale',
    name: 'AddSale',
    component: () => import('@/views/business/AddSale.vue'),
  },
];

export default routes;
