const routes = [
  {
    path: 'open',
    name: 'OpenOrders',
    component: () => import('@/views/orders/OpenOrders'),
    meta: {
      requiresAuth: true,
      requiresTeam: true,
    },
  },
  {
    path: 'history',
    name: 'OrderHistory',
    component: () => import('@/views/orders/OrderHistory'),
    meta: {
      requiresAuth: true,
      requiresTeam: true,
    },
  },
  {
    path: 'place',
    name: 'PlaceOrder',
    component: () => import('@/views/orders/PublicCreateOrder'),
  },
];

export default routes;
