import jwtDecode from 'jwt-decode';
import router from '@/router';

const state = {
  token: undefined,
  tokenClientIat: undefined,
  isLoggedIn: false,
  isTeam: false,
};

const getters = {
  token: ({ token }) => token,
  isLoggedIn: (state) => {
    return state.token !== undefined;
  },
  tokenPayLoad: (state) => {
    if (state.token) {
      return jwtDecode(state.token);
    }
  },
  isTokenValid: (state, getters) => () => {
    const payload = getters.tokenPayLoad;
    if (payload) {
      const maxAge = payload.exp - payload.iat;
      const currentTime = new Date().getTime() / 1000;
      const age = currentTime - state.tokenClientIat;
      if (age >= maxAge) {
        return false;
      } else {
        return true;
      }
    }
  },
  isTeam: (state, getters) => {
    const payload = getters.tokenPayLoad;
    if (payload) {
      return payload.isTeam;
    }
  },
};

const actions = {
};

const mutations = {
  setToken (state, token) {
    state.token = token;
    if (token) {
      state.tokenClientIat = new Date().getTime() / 1000;
    }
  },
  logout (state, reload = true) {
    if (reload) {
      router.push('/auth/login');
    }
    state.token = undefined;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
