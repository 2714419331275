import permission from './permission';

const routes = [
  {
    path: 'permission',
    name: 'Permissions',
    children: permission,
  },
  {
    path: 'usersessions',
    name: 'UserSessions',
    component: () => import('@/views/admin/UserSessions.vue'),
  },
  {
    path: 'users',
    name: 'AdminUsers',
    component: () => import('@/views/admin/AdminUsers.vue'),
  },
  {
    path: 'user/:id',
    name: 'AdminUser',
    component: () => import('@/views/admin/AdminUserPage.vue'),
  },
];

export default routes;
