<script setup>
import { useRoute } from 'vue-router';
import i18n from '@/i18n';

const route = useRoute();
</script>

<template>
  <div>
    <div class="pt-3 bg-color"/>
    <v-row class="bg bg-color">
      <v-col v-if="!route.meta.multiColor" cols="12" class="text-center d-flex flex-column align-center justify-center">
        <span class="main-title color-primary">{{ $t(`titles.${route.name}`) }}</span>
        <span class="sub-title">{{ $t(`texts.${route.name}`) }}</span>
      </v-col>
      <v-col v-else cols="12" class="text-center d-flex flex-column align-center justify-center">
        <span class="main-title">
          <span class="color-security">{{ i18n.global.t(`titles.${route.name}`).split(' ')[0] }} {{ ' ' }}</span>
          <span class="color-cards">{{ i18n.global.t(`titles.${route.name}`).split(' ')[1] }} {{ ' ' }}</span>
          <span class="color-garden">{{ i18n.global.t(`titles.${route.name}`).split(' ')[2] }}</span>
        </span>
        <span class="sub-title">{{ $t(`texts.${route.name}`) }}</span>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.bg {
  height: 41vh;
  background-image: url('@/assets/img/banner.webp');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  box-shadow: inset 0 -2px 2px rgb(var(--v-theme-surface));
}

.bg-color {
  background-color: rgb(var(--v-theme-onsurface));
}

.main-title {
  font-size: 6rem;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  text-shadow:
      4px 4px 4px black;
}

.sub-title {
  font-family: 'Poppins', sans-serif;
  font-size: 3rem;
  color: white;
  text-shadow:
      2px 2px 2px black;
}

@media screen and (max-width: 800px) {
  .main-title {
    font-size: 4rem;
  }
  .sub-title {
    font-size: 2rem;
  }
}

@media screen and (max-width: 500px) {
  .main-title {
    font-size: 2.5rem;
  }
  .sub-title {
    font-size: 1rem;
  }
}
</style>
