import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';

import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';

const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#00968f',
          garden: '#0b9931',
          secondary: '#909090',
          cards: '#ff9100',
          security: '#0522fa',
          surface: '#F5F5F5',
          onsurface: '#fff',
          'on-primary': '#232323',
          accent: '#ffc107',
          error: '#f44336',
          warning: '#e7802d',
          info: '#03a9f4',
          success: '#4caf50',
        },
      },
      dark: {
        dark: true,
        colors: {
          primary: '#00968f',
          secondary: '#909090',
          garden: '#0b9931',
          cards: '#ff9100',
          security: '#0522fa',
          'dark-background': '#232323',
          'dark-surface': '#303030',
          surface: '#5a5a5a',
          onsurface: '#535353',
          'on-primary': '#ffffff',
          accent: '#ffc107',
          error: '#f44336',
          warning: '#e7802d',
          info: '#03a9f4',
          success: '#4caf50',
        },
      },
    },
  },
});

export default vuetify;
