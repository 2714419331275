import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import auth from './auth';
import admin from './admin/admin';
import warehouse from './warehouse';
import business from './business';
import orders from './orders';
import cards from './cards';

const routes = [
  {
    path: '/auth',
    name: 'Auth',
    children: auth,
    meta: {
      banner: false,
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    children: admin,
    meta: {
      requiresAuth: true,
      requiresTeam: true,
    },
  },
  {
    path: '/warehouse',
    name: 'Warehouse',
    children: warehouse,
    meta: {
      requiresAuth: true,
      requiresTeam: true,
    },
  },
  {
    path: '/business',
    name: 'Business',
    children: business,
    meta: {
      requiresAuth: true,
      requiresTeam: true,
    },
  },
  {
    path: '/orders',
    name: 'Orders',
    children: orders,
  },
  {
    path: '/cards',
    name: 'Cards',
    children: cards,
    alias: ['/karten', '/anhänger', '/nfc', '/rfid'],
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home'),
    meta: {
      hideTop: true,
    },
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: () => import('@/views/Imprint'),
    alias: ['/impressum'],
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/Privacy'),
    alias: ['/datenschutz'],
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () => import('@/views/error/Unauthorized'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('@/views/error/NotFound'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      store.commit('setTempPath', to.path);
      next({ name: 'AuthLogin' });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresNotLoggedIn)) {
    if (store.getters.isLoggedIn) {
      next({ name: 'Home' });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresTeam)) {
    if (!store.getters.isTeam) {
      next({ name: 'Unauthorized' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
