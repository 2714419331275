<template>
  <div>
    <div v-if="!drawer && !noSites" class="activator" @click="drawer = true">
      TeamPanel
    </div>
    <v-navigation-drawer
        v-model="drawer"
        temporary
    >
      <v-divider></v-divider>
      <v-list density="compact" nav>
        <v-list-item>TeamPanel</v-list-item>
        <div>
          <v-list-item role="option" aria-label="Logout" prepend-icon="mdi-logout" :title="$t('auth.logout')" value="logout" @click="logout"></v-list-item>
          <v-divider />
          <div v-if="!loading">
            <v-list-item v-for="(item, i) of result?.getTeamSites?.sites" v-bind:key="i" :to="item.redirect" role="option" :aria-label="item.name" :prepend-icon="item.icon" :title="!item.translated ? $t(item.displayName) : item.displayName" :value="item.name"/>
          </div>
          <v-expansion-panels v-if="!loading">
            <v-expansion-panel v-for="(item, i) of result?.getTeamSites?.categories" v-bind:key="i">
              <v-divider/>
              <v-expansion-panel-title class="color-primary font-weight-bold"><v-icon size="20">{{ item.icon }}</v-icon><v-spacer/>{{ !item.translated ? $t(item.displayName) : item.displayName }}<v-spacer/></v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-list-item v-for="(item, i) of item.sites" v-bind:key="i" :to="item.redirect" role="option" :aria-label="item.name" :prepend-icon="item.icon" :title="!item.translated ? $t(item.displayName) : item.displayName" :value="item.name"/>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { useStore } from 'vuex';

const store = useStore();
const drawer = ref(true);
const noSites = ref(false);

const logout = () => store.commit('logout');
const { result, loading, onError } = useQuery(gql`
    query getTeamSites {
        getTeamSites {
            sites {
                name
                displayName
                icon
                redirect
                translated
            }
            categories {
                displayName
                icon
                translated
                sites {
                    name
                    displayName
                    icon
                    redirect
                    translated
                }
            }
        }
    }
`);
onError((err) => {
  if (err.message === 'NO_PERMISSION') return;
  if (err.message === 'NO_TEAM_SITES') {
    noSites.value = true;
    return;
  }
  console.log(err.message);
});
</script>

<style scoped>
.activator {
  position: fixed;
  z-index: 100;
  top: 40%;
  left: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 100px;
  transform: rotate(-90deg);
  transform-origin: 0 100%;
  background: rgb(var(--v-theme-dark-background));
  box-shadow: -1px 1px 5px black;
  color: white;
  cursor: pointer;
}
</style>
