const routes = [
  {
    path: 'all',
    name: 'AllPermissions',
    component: () => import('@/views/admin/permissions/AllPermissions'),
  },
  {
    path: 'groups',
    name: 'PermissionGroupsNRanks',
    component: () => import('@/views/admin/permissions/PermissionGroupsNRanks.vue'),
  },
];

export default routes;
