<template>
  <v-dialog
      v-model="dialog"
      class="dialog"
  >
    <v-card>
      <v-card-title>
        <v-icon icon="mdi-check-circle-outline" color="success"></v-icon>
        {{ $t(title) }}
      </v-card-title>
      <v-card-text class="color-secondary">
        {{ $t(text) }}
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="secondary" variant="elevated" @click="dialog = false">{{ $t('dialog.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed } from 'vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  title: String,
  text: String,
  modelValue: Boolean,
});

const dialog = computed({
  get: () => props.modelValue,
  set: (val) => {
    emit('update:modelValue', val);
  },
});
</script>

<style scoped>
.dialog {
  max-width: 500px;
}
</style>
