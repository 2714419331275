const routes = [
  {
    path: 'services',
    name: 'CardServices',
    component: () => import('@/views/cards/PublicServices'),
    alias: ['angebote', 'leistungen'],
  },
];

export default routes;
