<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();
const cookiesAccepted = computed({
  get () {
    return store.getters.cookiesAccepted;
  },
  set (val) {
    store.commit('setCookiesAccepted', val);
  },
});

const cookiesDialog = computed(() => !cookiesAccepted.value);

</script>

<template>
  <v-dialog
      v-if="!cookiesAccepted"
      v-model="cookiesDialog"
      class="dialog"
      vertical
      persistent
  >
    <v-card>
      <v-card-title>{{ $t("locales.select") }}</v-card-title>
      <v-card-item>
        <v-row>
          <v-col v-for="(locale, i) in $i18n.availableLocales" :key="i" class="mb-3">
            <div class="ma-2 pa-2 fill-height d-flex justify-center align-center flex-column">
              <v-card-title>{{ $t(`locales.${locale}`) }}</v-card-title>
              <img :src="utils.getImgUrl(`locales/${locale}.png`)" class="ma-3" :alt="locale">
              <v-btn
                  v-on:click="store.commit('setLocale', locale);"
                  :color="$i18n.locale === locale ? 'primary' : 'secondary'"
                  :aria-label="locale"
              >
                {{ $t('dialog.select') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-item>
    </v-card>
    <v-card
        class="mt-2"
      prepend-icon="mdi-cookie"
    >
      <template v-slot:title>
        {{ $t('cookies.title') }}
      </template>

      <v-card-text>
        {{ $t('cookies.text') }}
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
            color="primary"
            variant="elevated"
            @click="cookiesAccepted = true"
        >
          {{ $t('dialog.accept') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.dialog {
  width: 100%;
  max-width: 600px;
}
</style>
